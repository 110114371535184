@import "styles/mixins";

.footer {
	margin-top: 94px;
	border-top: 1px solid #d9d9d9;
	background-color: white;
	padding: 47px 0px 0px 0px;

	a {
		width: fit-content;
		min-height: 48px;
		display: flex;
		text-decoration: none;
		align-items: center;
	}
	.hstack {
		width: 100%;
		justify-content: space-between;
	}

	.bottomHStack {
		border-top: 1px solid #d9d9d9;
		margin-top: 38px;
		padding-top: 14px;
		justify-content: space-between;
		.inContainer {
			align-items: center;
			gap: 13px;
			.linkedin {
				cursor: pointer;
				font-family: var(--avena-font-family);
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 17px;
				color: var(--avena-accent-color-primary);
			}
		}
		.copyright {
			font: var(--avena-font-xs-regular);
			color: var(--avena-text-color-primary);
		}
	}

	.footerMask {
		margin-top: 14px;
		.image {
			width: 100%;
			position: absolute;
			left: 0;
			right: 0;
			z-index: 1;
		}
	}

	.hrSolid {
		width: 100%;
		border-bottom: 1px solid #d7d7d7;
		margin: 20px 0px;
	}
	.hrDashed {
		width: 100%;
		border-bottom: 1px dashed #d7d7d7;
		margin: 20px 0px;
	}
	.connectContainer {
		justify-content: flex-end;
		.connect {
			font-family: var(--avena-font-family);
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 12px;
			color: var(--avena-accent-color-tertiary);
		}
	}

	.addressContainer {
		font-weight: 300;
		font-size: 14px;
		line-height: 21px;
		color: #404040;
	}

	.info {
		margin-top: 47px;
		margin-bottom: 19px;
		font-family: var(--avena-font-family);
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		color: var(--avena-text-color-primary);
	}

	.rightStack {
		gap: 8px;
		span {
			font: var(--avena-font-l-semibold);
			height: 27px;
			margin-bottom: 15px;
		}
		div {
			color: var(--avena-text-color-primary);
			font-family: var(--avena-font-family);
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 30.8px;
		}
	}

	.top_bar {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		.brand {
			font: 400 14px/21px var(--avena-font-family);
			color: #404040;
		}

		.icon_links {
			display: flex;
			flex-direction: row;
			gap: 32px;

			.group {
				display: flex;
				flex-direction: row;
				gap: inherit;

				.divider {
					display: none;
				}

				.avena2021 {
					cursor: default;

					&:hover {
						text-decoration: none;
					}
				}

				div {
					color: #595959;
					font-weight: 300;
				}

				a {
					color: #595959;
					font-weight: 300;
				}
			}
		}
	}
}

@include mobile {
	.footer {
		padding: 14px;

		.top_bar {
			.icon_links {
				flex-direction: column;
				gap: 10px;
				font-size: 12px;

				.group {
					justify-content: center;

					.divider {
						display: initial;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 1350px) {
	.footer {
		padding: 47px 14px 0px 14px;

		.connectContainer {
			display: none;
		}
	}
}
