@import "styles/variables.scss";
@import "styles/mixins";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700&display=swap");
@import "../fonts/poppins.scss";

html {
	// @media only screen and (min-width: 1024px) {
	//     font-size: 14px;
	// }

	// @media only screen and (min-width: 1366px) {
	//     font-size: 16px;
	// }

	@media only screen and (min-width: 2560px) {
		font-size: 20px;
	}

	@media only screen and (min-width: 2880px) {
		font-size: 24px;
	}
}

html,
body,
#root {
	font-family: "Poppins", sans-serif;
	font-size: 16px;
	display: flex;
	flex-direction: column;
	line-height: normal;
	margin: 0;
	width: 100%;
	height: 100%;

	* {
		&::-webkit-scrollbar {
			width: 5px;
			height: 5px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 5px #d1d1d1;
			border-radius: 10px;
		}
		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: hsl(210, 69%, 19%); //var(--text-color-primary)
			border-radius: 10px;
		}
		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: hsl(210, 69%, 14%);
		}
		/* Handle on click */
		&::-webkit-scrollbar-thumb:active {
			background: hsl(210, 69%, 14%);
		}
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

h1 {
	font-size: var(--font-size-xl);
}

h2 {
	font-size: var(--font-size-lg);
}

h3 {
	font-size: var(--font-size-md);
}

h4 {
	font-size: var(--font-size-sm);
}

h5 {
	font-size: var(--font-size-xs);
}

h6 {
	font-size: var(--font-size-xs);
}

p {
	font-size: var(--font-size-md);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: inherit;
	margin: 0.5em 0em;
	font-weight: 400;
}

ul {
	padding-inline-start: var(--padding-lg);
}

li {
	margin-bottom: 0.5em;
}

a {
	color: var(--text-color-blue);
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: calc(100%);
		left: 50%;
		height: 1px;
		width: 0%;
		background: var(--text-color-blue);
		@include transition(300ms);
		@include transform(rotate(180deg));
		transform-origin: left;
	}
	&::after {
		content: "";
		position: absolute;
		top: calc(100%);
		left: 50%;
		height: 1px;
		width: 0%;
		background: var(--text-color-blue);
		@include transition(300ms);
	}

	&:not(.nohover) {
		&:hover {
			&::before {
				width: 50%;
			}
			&::after {
				width: 50%;
			}
		}
	}
}

.ant-btn {
	border: none;
	box-shadow: none;
	font-size: var(--font-size-sm);
	height: auto;
	padding: var(--padding-sm) var(--padding-xl);
}

.ant-btn-link {
	padding: var(--padding-sm);
}

.ant-checkbox-wrapper {
	font-size: var(--font-size-sm);
}

.ant-drawer-header {
	background-color: var(--page-header-background-color);

	.ant-drawer-title {
		color: var(--text-color-primary);
	}
}

.ant-drawer-wrapper-body {
	background-color: var(--input-background-color);
}

.ant-drawer-header,
.ant-drawer-footer {
	border-color: var(--border-color);
}

.ant-input {
	font-size: var(--font-size-sm);
}

.ant-input-number-input-wrap {
	font-size: var(--font-size-sm);
}

.ant-picker-input > input {
	font-size: var(--font-size-sm);
}

.ant-select-selector {
	background-color: var(--input-background-color) !important;
	border-color: var(--border-color) !important;
	height: 44px !important;
	padding: 8px !important;
	padding-top: 6px !important;
}

.ant-select-item,
.ant-select-dropdown,
.ant-select-item-option-selected {
	background-color: var(--input-background-color) !important;
}

.ant-select-item,
.ant-select-item-option-selected,
.ant-select-selection-placeholder,
.ant-select-selection-item {
	color: var(--input-foreground-color) !important;
	font-size: var(--font-size-sm);
}

.ant-select-item-option-active {
	background-color: var(--input-background-color-disabled) !important;
	color: var(--input-foreground-color-disabled) !important;
}

.ant-select-single:not(.ant-select-customize-input)
	.ant-select-selector
	.ant-select-selection-search-input {
	font-size: var(--font-size-sm);
	height: 2rem;

}

.ant-carousel .slick-dots-bottom {
	gap: 8px;
}

.ant-carousel .slick-dots li.slick-active button {
	background: var(--avena-accent-color-primary);
}

.ant-carousel .slick-dots li {
	background: inherit;
	margin: 0px;
	padding: 22.5px 16px;
	cursor: pointer;
}

.ant-carousel .slick-dots li button {
	background: #D7D7D7;
	cursor: pointer;
	width: 20px;
    height: 20px;
    border-radius: 50%;
}


.ant-table {
	font-size: var(--font-size-sm);
}

.ant-table-tbody > tr > td {
	padding: var(--padding-lg);
}

.ant-tag {
	font-size: var(--font-size-sm);
	padding: var(--padding-xs) var(--padding-lg);
}

@media print {
	body * {
		visibility: hidden;
	}

	.printable,
	.printable * {
		visibility: visible;
	}

	.printable {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}

textarea {
	background: var(--avena-input-background-color);
	border: none;

	&:focus-visible {
		outline: none;
	}
}

:root {
	--avena-font-family: Poppins;

	--avena-font-xxs-regular: 400 10px/15px var(--avena-font-family);
	--avena-font-xxs-medium: 500 10px/15px var(--avena-font-family);
	--avena-font-xxs-semibold: 600 10px/15px var(--avena-font-family);
	--avena-font-xxs-bold: 700 10px/15px var(--avena-font-family);

	--avena-font-xs-regular: 400 12px/18px var(--avena-font-family);
	--avena-font-xs-medium: 500 12px/18px var(--avena-font-family);
	--avena-font-xs-semibold: 600 12px/18px var(--avena-font-family);
	--avena-font-xs-bold: 700 12px/18px var(--avena-font-family);

	--avena-font-s-regular: 400 14px/21px var(--avena-font-family);
	--avena-font-s-medium: 500 14px/21px var(--avena-font-family);
	--avena-font-s-semibold: 600 14px/21px var(--avena-font-family);
	--avena-font-s-bold: 700 14px/21px var(--avena-font-family);

	--avena-font-m-regular: 400 16px/24px var(--avena-font-family);
	--avena-font-m-medium: 500 16px/24px var(--avena-font-family);
	--avena-font-m-semibold: 600 16px/24px var(--avena-font-family);
	--avena-font-m-bold: 700 16px/24px var(--avena-font-family);

	--avena-font-l-regular: 400 18px/27px var(--avena-font-family);
	--avena-font-l-medium: 500 18px/27px var(--avena-font-family);
	--avena-font-l-semibold: 600 18px/27px var(--avena-font-family);
	--avena-font-l-bold: 700 18px/27px var(--avena-font-family);

	--avena-font-xl-regular: 400 20px/30px var(--avena-font-family);
	--avena-font-xl-medium: 500 20px/30px var(--avena-font-family);
	--avena-font-xl-semibold: 600 20px/30px var(--avena-font-family);
	--avena-font-xl-bold: 700 20px/30px var(--avena-font-family);

	--avena-font-xxl-regular: 400 24px/36px var(--avena-font-family);
	--avena-font-xxl-medium: 500 24px/36px var(--avena-font-family);
	--avena-font-xxl-semibold: 600 24px/36px var(--avena-font-family);
	--avena-font-xxl-bold: 700 24px/36px var(--avena-font-family);

	--avena-font-header-xl-bold: 600 46px/56px var(--avena-font-family);
	--avena-font-header-l-bold: 600 42px/63px var(--avena-font-family);

	// text colors
	--avena-text-color-primary: #404040;
	--avena-text-color-secondary: #707070;
	--avena-text-color-tertiary: #a0a0a0;
	--avena-text-color-quaternary: #d0d0d0;
	--avena-text-color-white: #ffffff;

	--avena-text-color-link: #0066cc;
	--avena-text-color-disabled: #b5b8bb;
	--avena-text-color-icons: #7c7b7b;
	--avena-text-color-stroke: #d7d7d7;
	--avena-text-color-dividers: #eff2f5;
	--avena-text-color-disabled-bg: #f2f6f9;
	--avena-text-color-white: #ffffff;

	// accent colors
	--avena-accent-color-primary: #002f5e;
	--avena-accent-color-secondary: #004f9d;
	--avena-accent-color-tertiary: #0066cc;
	--avena-accent-color-quaternary: #0080ff;

	--avena-accent-color-bg: #eff7ff;
	--avena-accent-color-hover-state: #369bff;
	--avena-accent-color-click-state: #014992;

	// gradients
	--avena-gradient-color-primary: linear-gradient(
		267.67deg,
		#597ef7 7.9%,
		#ca61a2 112.59%
	);
	--avena-gradient-color-secondary: linear-gradient(
		255.33deg,
		#0066cc -9%,
		#02c58a 157.39%
	);
	--avena-gradient-color-tertiary: linear-gradient(
		267.55deg,
		#1890ff 58.86%,
		#012d84 139.54%
	);

	// system colors
	--avena-system-color-successful-hover: #28b411;
	--avena-system-color-successful-primary: #3ac922;
	--avena-system-color-successful-secondary: #b2ffa6;
	--avena-system-color-successful-tertiary: #e6ffe2;

	--avena-system-color-attention-text: #ad8b00;
	--avena-system-color-attention-hover: #f7d523;
	--avena-system-color-attention-primary: #f7d523;
	--avena-system-color-attention-secondary: #ffef98;
	--avena-system-color-attention-tertiary: #fffae1;

	--avena-system-color-error-hover: #ed2121;
	--avena-system-color-error-primary: #ff3030;
	--avena-system-color-error-secondary: #ffc0c0;
	--avena-system-color-error-tertiary: #fff2f2;

	// animations
	--avena-transition: 0.25s;

	// buttons
	--avena-button-background-color: #0066cc;
	--avena-button-background-color-disabled: #f9fbfd;
	--avena-button-border: none;
	--avena-button-border-radius: 4px;
	--avena-button-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
	--avena-button-text-color: #ffffff;
	--avena-button-text-color-disabled: #a0a0a0;
	--avena-button-padding-lg: 12px 32px;
	--avena-button-padding-md: 8.5px 21px;
	--avena-button-padding-sm: 6px 16px;

	// cards
	--avena-card-background-color: #ffffff;
	--avena-card-border: none;
	--avena-card-border-radius: 8px;
	--avena-card-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);

	// inputs
	--avena-input-background-color: #f9fbfd;
	--avena-input-background-color-disabled: #f9fbfd;
	--avena-input-border: none;
	--avena-input-border-radius: 4px;
	--avena-input-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);

	--avena-input-border-focus: none;
	--avena-input-shadow-focus: 0px 1px 1px rgba(21, 125, 227, 0.4);

	// spacing
	--avena-spacing-xs: 2px;
	--avena-spacing-sm: 4px;
	--avena-spacing-md: 8px;
	--avena-spacing-lg: 16px;
	--avena-spacing-xl: 32px;

	// z-index
	--avena-overlay-z-index: 100;
	// gradients
	--gradient-primary: linear-gradient(
		267.67deg,
		#597ef7 7.9%,
		#ca61a2 112.59%
	);
	--gradient-secondary: linear-gradient(
		268.54deg,
		#02c58a -39.69%,
		#11a7a8 3.44%,
		#257fd1 56.62%
	);
	--gradient-tertiary: linear-gradient(
		267.55deg,
		#1890ff 58.86%,
		#012d84 139.54%
	);
}
