.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    width: 100%;
    max-width: 1366px;
    // height: fit-content;
    height: 100%;
    overflow: hidden;
}
