@import "styles/mixins";

.row {
    display: grid;
    flex: 1 1 0px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

@include mobile {
    .row {
        display: flex;
        flex-direction: column;
    }
}