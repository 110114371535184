:root {
    // border-radius
    --border-radius-xs: 0.125rem;
    --border-radius-sm: 0.25rem;
    --border-radius-md: 0.5rem;
    --border-radius-lg: 0.75rem;
    --border-radius-xl: 1rem;

    --border-rounded: 50% 50%;

    // font-sizes
    --font-size-xs: 0.625rem;
    --font-size-sm: 0.875rem;
    --font-size-md: 1rem;
    --font-size-lg: 1.375rem;
    --font-size-xl: 1.75rem;
    --font-size-xxl: 2rem;
    --font-size-xxxl: 2.5rem;

    // paddings
    --padding-xs: 0.125rem;
    --padding-sm: 0.25rem;
    --padding-md: 0.5rem;
    --padding-lg: 1rem;
    --padding-xl: 1.5rem;
    --padding-xxl: 2rem;

    // margins
    --margin-xs: 0.125rem;
    --margin-sm: 0.25rem;
    --margin-md: 0.5rem;
    --margin-lg: 1rem;
    --margin-xl: 1.5rem;
    --margin-xxl: 2rem;

    // background colors
    --background-color: #f8fafc;
    --background-color-secondary: #f7faff;

    // text colors
    --text-color-primary: #0f3152;
    --text-color-secondary: #8494ab;
    --text-color-tertiary: #b7b7b7;
    --text-color-blue: #0066cc;
    --text-color-turquoise: #04a6b7;

    --danger-color: #d73640;

    // link colors
    --link-color: #006bdb;
    --link-color-disabled: #cccccc;

    --sidebar-background-color: #0056a5;
    --sidebar-foreground-color: #ffffff;
    --sidebar-border-size: 0px;
    --sidebar-border-color: #0056a5;

    --page-header-background-color: #ffffff;
    --page-header-border-size: 1px;
    --page-header-border-color: #efeff4;
    --page-header-foreground-color: #efeff4;

    //warning
    --warning-border-color: #f2bc89;
    --warning-background-color: #fbf3eb;

    // border colors
    --border-color: #cdcdcd;
    // --border-color: #e0e0e0;
    --border-color-active: #1890ff;

    // dialog colors
    --dialog-background-color: #efeff4;
    --header-background-color: #1890ff;

    // button colors
    --button-background-color: #1890ff;
    --button-background-color-blue: #257fd1;
    --button-background-color-active: #62a5ff;
    --button-background-color-disabled: #cccccc;
    --button-foreground-color: #ffffff;
    --button-foreground-color-active: #ffffff;
    --button-foreground-color-disabled: #ffffff;

    // inputs
    --input-height: 2.5rem;

    --input-border-color: var(--border-color);
    --input-border-radius: 0.125rem;

    --input-background-color: #ffffff;
    --input-background-color-disabled: #efeff4;
    --input-foreground-color: initial;
    --input-foreground-color-disabled: initial;
}

:root.dark-theme {
    --background-color: #1e1e1e;
    --background-color-secondary: #53595d;

    --page-header-background-color: #3c3c3c;
    --page-header-border-color: #303030;
    --page-header-foreground-color: #303030;

    --text-color-primary: #ffffff;
    --text-color-secondary: #e6e6e6;
    --text-color-tertiary: #cccccc;

    --dialog-background-color: #303030;

    --input-background-color: #252526;
    --input-background-color-disabled: #14141a;
    --input-foreground-color: var(--text-color-primary);
    --input-foreground-color-disabled: var(--text-color-secondary);

    --border-color: #3c3c3c;

    // Chromium input overrides
    ::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }
}
