@import "styles/mixins";

.header {
	flex-direction: row;
	padding: 26px;
	z-index: 1;

	.trialCard {
		padding: 8.5px 18px;
		border-radius: 4px;
		background-color: #ffffff;
		color: var(--avena-accent-color-secondary);
		&:hover {
			cursor: pointer;
		}
		&.light {
			color: #ffffff;
			background-color: var(--avena-accent-color-tertiary);
		}
	}

	.headerContent {
		align-items: center;
		gap: 40px;

		.navlink {
			color: #404040;
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
		}

		a {
			font: var(--avena-font-);
			text-decoration: none;
			user-select: none;
		}

		.desktopLink:hover {
			color: #002f5e;
			fill: #002f5e;
		}

		.ppContainer {
			cursor: pointer;
			width: 32px;
			height: 32px;
			background: var(--avena-text-color-white);
			border-radius: 50%;
			padding: 19px;
			border: none;

			@include flexCenter();
			margin-right: 8px;

			.userIcon {
				width: 32px;
				height: 32px;
				border-radius: 50%;
			}
		}

		.creditInnerCard {
			background: #ffffff;
			padding: 6px 10px;
			gap: 6px;
			min-width: 180px;
			box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
			border-radius: 4px;

			.creditContainer {
				justify-content: space-between;
				align-items: center;
				font: var(--avena-font-xs-medium);
			}

			.availableCredits {
				color: var(--avena-accent-color-tertiary);
				align-items: center;
				gap: 5px;
			}

			.slider {
				background: #eff2f5;
				border-radius: 3px;
				width: 100%;
				height: 6px;
				.inner {
					@extend .slider;
					background: #004F9D;
				}
			}
		}

		.contactUsButton {
			padding: 12px 32px;
		}
	}
}

.dark {
	background: var(--gradient-primary);
	a {
		color: #ffffff;
	}
}

.light {
	background: #ffffff;
	a {
		color: #404040;
	}
}

@media (max-width: 1350px) {
	.header {
		padding: 12px;
		.hamburgerMenu {
			height: 100%;
			width: 0px;
			position: fixed;
			z-index: 2;
			top: 0;
			left: 0;
			overflow-x: hidden;
			transition: 0.5s;
			padding: 0px;
			font-size: 16px;
			text-align: left;
			transition-duration: 0.5s;
			background-color: #f7f8fb;
			color: var(--avena-accent-color-primary);
			border-radius: 0px;

			.VStack {
				justify-content: space-between;
				height: 100%;
				.topStack {
					align-items: center;
					justify-content: space-between;
					padding: 14px;
				}
				.hr {
					border-bottom: 1px solid #d7d7d7;
					width: 100%;
				}

				.itemContainer {
					margin: 16px 14px;
					text-decoration: none;
					width: fit-content;
					align-items: center;
					gap: 16px;
					font: var(--avena-font-m-regular);
					color: var(--avena-text-color-primary);
				}

				.closeSideNavIcon {
					color: var(--avena-accent-color-primary);
					cursor: pointer;
					display: initial;
				}

				.bottomLogoStack {
					padding-bottom: 20px;
					.bottomTextStack {
						margin-left: 9px;
						border-left: 1px solid #d7d7d7;
						padding-left: 9px;
						font-weight: 500;
						font-size: 10px;
						line-height: 127%;
						color: var(--avena-accent-color-tertiary);
					}
				}
				.link {
					font: var(--avena-font-xxs-regular);
					color: var(--avena-text-color-secondary);
				}
				.bottomBackground {
					height: 10px;
					background: linear-gradient(
						90.32deg,
						#35a4f0 -15.32%,
						#66daf9 28.85%,
						#46b7f3 89.46%,
						#2a97ee 121.45%
					);
				}
			}
		}
		.headerContent {
			gap: 10px;
			.desktopLink {
				display: none;
			}
		}
		.trialCard {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 7.5px 18px;
			margin-top: 5px;
			font: var(--avena-font-xxs-medium);
			&.light {
				color: #ffffff;
				background-color: var(--avena-accent-color-tertiary);
			}
		}
		.contactUsButton {
			padding: 6px 14px !important;
		}
	}
}

