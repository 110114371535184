@mixin border-radius($i) {
    border-radius: $i;
    -webkit-border-radius: $i;
    -moz-border-radius: $i;
    -ms-border-radius: $i;
    -o-border-radius: $i;
}
@mixin transition($i) {
    transition: $i;
    -webkit-transition: $i;
    -moz-transition: $i;
    -ms-transition: $i;
    -o-transition: $i;
}
@mixin transform($i) {
    transform: $i;
    -webkit-transform: $i;
    -moz-transform: $i;
    -ms-transform: $i;
    -o-transform: $i;
}
@mixin animation($i) {
    animation: $i;
    -webkit-animation: $i;
}

@mixin flexCenter() {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin mobile {
    @media only screen and (max-width: 767px) {
        @content;
    }
}

@mixin tablet2 {
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        @content;
    }
}

@mixin smallScreen {
    @media only screen and (max-width: 1023px) {
        @content;
    }
}

@mixin selectNone {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}