.button {
    background-color: var(--avena-button-background-color);
    color: var(--avena-button-text-color);
    cursor: pointer;
    border: var(--avena-button-border);
    border-radius: var(--avena-button-border-radius);
    box-shadow: var(--avena-button-shadow);
    font: var(--avena-font-m-medium);
    outline: none;
    padding: var(--avena-button-padding-md);
    transition: all var(--avena-transition);
}

.primary {
    &:hover {
        background: var(--avena-accent-color-hover-state);;
    }

    &:active {
        background: var(--avena-accent-color-click-state);
    }

    &:disabled {
        border: none;
        background-color: var(--avena-button-background-color-disabled);
        color: var(--avena-button-text-color-disabled);
        cursor: not-allowed;
    }
}

.secondary {
    background-color: inherit;
    border: 1px solid var(--avena-accent-color-tertiary);
    box-sizing: border-box;
    color: var(--avena-accent-color-tertiary);

    &:hover {
        border: 1px solid var(--avena-accent-color-hover-state);
        color: var(--avena-accent-color-hover-state);
    }

    &:active {
        color: var(--avena-accent-color-click-state);
        border: 1px solid var(--avena-accent-color-click-state);
    }

    &:disabled {
        border: none;
        background-color: var(--avena-button-background-color-disabled);
        color: var(--avena-button-text-color-disabled);
        cursor: not-allowed;
    }
}

.tertiary {
    background: inherit;
    color: var(--avena-accent-color-tertiary);

    &:hover {
        box-shadow: 0px 1px 1px rgba(21, 125, 227, 0.4);
        color: var(--avena-accent-color-hover-state);
    }

    &:active {
        color: var(--avena-accent-color-click-state);
        box-shadow: 0px 1px 1px rgba(1, 73, 146, 0.8);
    }

    &:disabled {
        border: none;
        background-color: var(--avena-button-background-color-disabled);
        color: var(--avena-button-text-color-disabled);
        cursor: not-allowed;
        box-shadow: var(--avena-button-shadow);
    }
}

.paddingLarge {
    padding: var(--avena-button-padding-lg);
}

.paddingSmall {
    padding: var(--avena-button-padding-sm);
}
